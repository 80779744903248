<template>
  <v-container>
    <h1 class="text-h4">Semua Berita</h1>
    <v-row>
      <v-row v-if="loading">
        <v-col xs="12" sm="12" md="4" v-for="n in 3" :key="n">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-col v-else cols="12" xs="12" sm="6" md="4" lg="3" v-for="news in newsfeed" :key="news.id">
        <news-card :news-item="news" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewsCardVue from '@/components/NewsCard.vue'
import firebase from '@/plugins/firebase'

export default {
  name: 'NewsListView',

  components: {
    'news-card': NewsCardVue
  },

  data () {
    return ({
      loading: false,
      newsfeed: []
    })
  },

  methods: {
    getLatestNews () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection('news').where('published', '==', true).orderBy('datePublished', 'desc').limit(5).get()
          .then(snapshot => {
            var resolution = snapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data()))
            resolve(resolution)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    this.$data.newsfeed = await this.getLatestNews()
    this.$data.loading = false
  }
}
</script>
